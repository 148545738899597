import { useState } from 'react';
import { Link } from 'react-router-dom';

const Home3Plane = () => {
  const [controlsVisible, setControlsVisible] = useState(true);

  const playFunVideo = () => {
    const videoEl = document.getElementById('fun_video');
    if (controlsVisible) {
      videoEl.volume = 0.05;
      videoEl.play();
      videoEl.onended = () => {
        setControlsVisible(true);
      };
    } else {
      videoEl.pause();
    }
    setControlsVisible(!controlsVisible);
  }

  return (
    <div className="cta_box style_2">
      <div className="container relative z-2">
        <div className="bg-thm-color-two cta_box_inner z-2 mb-negative">
          <img
            src="assets/images/elements/shape.png"
            alt="shape"
            className="shape_1 zoom-fade"
          />
          <div className="row justify-content-between align-items-center footer_fun">
            <div className="col-lg-6">
              <div className="text_block wow fadeInLeft">
                <div className="section-title left-align white">
                  <h3 className="title">
                    Have So Much Fun That You Forget You're Exercising!
                  </h3>
                  <Link to="/contact" className="thm-btn bg-thm-color-three thm-color-three-shadow btn-rectangle">
                    Contact Me <i className="fal fa-chevron-right ml-2" />
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="image_box relative z-1 wow fadeInRight">
                <img
                  src="assets/images/elements/square_shape.png"
                  alt="shape"
                  className="shape_2 rotate_elem"
                />
                {/* <img
                  src="assets/images/cta_image.png"
                  alt="img"
                  className="image-fit"
                /> */}
                <div className="video_wrapper_border">
                  <video id="fun_video" className="video-js video-fun" src="assets/videos/fun.mp4" type="video/mp4" height="425" onClick={!controlsVisible ? playFunVideo : () => { }}></video>
                  {controlsVisible && <div className="player_controls" onClick={playFunVideo}>&#9658;</div>}
                </div>
                <div className="arrows slideRight">
                  <div className="arrow" />
                  <div className="arrow" />
                  <div className="arrow" />
                  <div className="arrow" />
                  <div className="arrow" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="arrows to_up slideTop">
          <div className="arrow" />
          <div className="arrow" />
          <div className="arrow" />
          <div className="arrow" />
          <div className="arrow" />
        </div>
      </div>
    </div>
  );

  // return (
  //   <div className="bg-thm-color-two plane_box relative z-1">
  //     <div className="container relative z-1">
  //       <div className="row justify-content-center">
  //         <div className="col-xl-9 col-lg-11 relative z-1">
  //           <img
  //             src="assets/images/elements/element_6.png"
  //             className="element_1 rotate_elem"
  //             alt="Element"
  //           />
  //           <h2 className="thm-color-white">
  //             Have So Much Fun That You Forget You're Exercising!
  //           </h2>
  //         </div>
  //       </div>
  //       <img
  //         src="assets/images/elements/element_7.png"
  //         className="element_2 zoom-fade"
  //         alt="Element"
  //       />
  //     </div>
  //   </div>
  // );
};

export default Home3Plane;
