import { useState } from 'react';
import { Link } from 'react-router-dom';
import FooterContact from "../components/FooterContact";
import PageTitleBanner from "../components/PageTitleBanner";
import Price from "../components/Price";
import ImagePopup from "../components/ImagePopup";
import Layout from "../layout/Layout";

const Pricing = () => {
  const [graph, setGraph] = useState(false);

  return (
    <Layout>
      <PageTitleBanner pageName="Packages and Pricing" />

      {graph && <ImagePopup close={() => setGraph(false)} imageUrl="assets/images/custom/graph.png" />}

      <section className="pricing_sec section">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="section-title wow fadeInDown">
                {/* <p className="subtitle">
                  <i className="fal fa-book" />
                  Pricing Plans
                </p> */}
                <h3 className="title">
                  Packages and Pricing
                </h3>
                <p className="subtitle">
                  The more time you spend moving, the greater the health benefits!{' '}
                  <small>(<a
                    onClick={() => setGraph(true)}
                    href="#"
                  >see here</a>)</small>
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <Price />
            </div>
          </div>
        </div>
      </section>
      {/* Pricing End */}
      {/* Portfolio Start */}
      {/* <section className="section-padding pt-0">
        <div className="container">
          <div className="row align-items-end">
            <div className="col-lg-7">
              <div className="section-title left-align wow fadeInLeft">
                <p className="subtitle">
                  <i className="fal fa-book" />
                  Project Gallery
                </p>
                <h3 className="title">
                  We Have 34520+ Project Successfully Done
                </h3>
              </div>
            </div>
            <div className="col-lg-5 text-left text-lg-right wow fadeInRight">
              <Link to="/portfolio-grid">
                <a className="thm-btn bg-thm-color-white thm-color-one btn-border btn-rectangle mb-xl-60">
                  View All Project
                  <i className="fal fa-chevron-right ml-2" />
                </a>
              </Link>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <div className="portfolio_box wow fadeInUp">
                <div className="portfolio_img">
                  <img
                    src="assets/images/portfolio/1.jpg"
                    className="image-fit"
                    alt="img"
                  />
                </div>
                <div className="portfolio_caption">
                  <div className="text_box">
                    <h4 className="title mb-0">
                      <Link to="/portfolio-details">
                        How To Gain Knowledge
                      </Link>
                    </h4>
                    <p>Business Coach</p>
                  </div>
                  <div className="btn_box mb-xl-30">
                    <Link to="/portfolio-details">
                      <a className="thm-btn bg-thm-color-two-light thm-color-two btn-rectangle btn-small">
                        <i className="fal fa-chevron-right font-weight-bold" />
                      </a>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="portfolio_box wow fadeInDown">
                <div className="portfolio_img">
                  <img
                    src="assets/images/portfolio/2.jpg"
                    className="image-fit"
                    alt="img"
                  />
                </div>
                <div className="portfolio_caption">
                  <div className="text_box">
                    <h4 className="title mb-0">
                      <Link to="/portfolio-details">
                        Online Consulting Tutorials
                      </Link>
                    </h4>
                    <p>Business Coach</p>
                  </div>
                  <div className="btn_box mb-xl-30">
                    <Link to="/portfolio-details">
                      <a className="thm-btn bg-thm-color-two-light thm-color-two btn-rectangle btn-small">
                        <i className="fal fa-chevron-right font-weight-bold" />
                      </a>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      {/* Portfolio End */}
      <FooterContact />
    </Layout >
  );
};

export default Pricing;
