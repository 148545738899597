import { Link } from 'react-router-dom';
import { useState } from "react";
import Slider from "react-slick";
import Counter from "../components/Counter";
import PageTitleBanner from "../components/PageTitleBanner";
import VideoPopup from "../components/VideoPopup";
import Layout from "../layout/Layout";
import { testimonial_slider } from "../sliderProps";

const About = () => {
  const [video, setVideo] = useState(false);
  return (
    <Layout headerStyle={3} footerStyle={2}>
      <PageTitleBanner pageName="About Me" />
      {video && <VideoPopup close={() => setVideo(false)} />}
      <section className="section about_inner">
        <div className="container">
          <div className='row'>
            <div className="col-lg-12">
              <h3 className="title">
                My Experiences and My Mission
              </h3>
            </div>
          </div>
          <div className="row ">
            <div className="col-lg-4">
              <div className="image_box shadow_1 mb-md-80 wow fadeInLeft">
                <img
                  src="assets/images/custom/kristina_profile_picture.jpeg"
                  alt="img"
                  className="image-fit"
                />
              </div>
            </div>
            <div className="col-lg-8">
              <div className="section-title left-align wow fadeInDown">
                <p>
                  Since as long as I could remember, I loved to move. Playing on the playground, running around outside, playing sports, you name it. So, when it came time to choose a career path, I had no doubt that I should pursue the field of exercise science. The more exposure I got in the field, the more I noticed that individuals simply endure/tolerate their exercise regime, but don’t find pleasure in it. My goal is to pass along my love for movement to my clients so their training plans are not only tolerable, but enjoyable. I have years of experience working in The Human Performance Research Labortory at YorkU conducting fitness assessments for occupational firefighter applicants, participating in various research studies in the fitness field, working with women pre and post menopause, and providing lifetsyle counselling to children and their families at Sickkids Hospital. My mission is to combine my love of movement with my years of clinical experience to provide my clients with enjoyable and challenging exercise regimes as well as provide them with the support necessary to optimize their entire lifestyle.
                </p>
              </div>
              <ul className="about_list row">
                <li className="col-md-6 wow fadeInUp">
                  <div className="icon">
                    <img
                      src="assets/images/icons/graduation_cap.svg"
                      alt="img"
                      className="image-fit"
                    />
                  </div>
                  <div className="text">
                    <h6 className="mb-2">Masters in Fitness Science</h6>
                    <p className="mb-0">
                      York University
                    </p>
                  </div>
                </li>
                <li className="col-md-6 wow fadeInDown">
                  <div className="icon">
                    <img
                      src="assets/images/icons/graduation_cap.svg"
                      alt="img"
                      className="image-fit"
                    />
                  </div>
                  <div className="text">
                    <h6 className="mb-2">Bachelor of Science in Kinesiology</h6>
                    <p className="mb-0">
                      York University
                    </p>
                  </div>
                </li>
                <li className="col-md-6 wow fadeInUp">
                  <div className="icon">
                    <img
                      src="assets/images/icons/diploma_1.svg"
                      alt="img"
                      className="image-fit"
                    />
                  </div>
                  <div className="text">
                    <h6 className="mb-2">Certificate in Fitness Assessment and Exercise Councelling</h6>
                    <p className="mb-0">
                      The Human Performance Research Laboratory, York University
                    </p>
                  </div>
                </li>
                <li className="col-md-6 wow fadeInDown">
                  <div className="icon">
                    <img
                      src="assets/images/icons/diploma_1.svg"
                      alt="img"
                      className="image-fit"
                    />
                  </div>
                  <div className="text">
                    <h6 className="mb-2">Certified Exercise Physiologist</h6>
                    <p className="mb-0">
                      The Health and Fitness Federation of Canada
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      {/* About End */}
      {/* About Us Start */}
      {/* <section
        className="section section-bg about_bg about style_2"
        style={{ backgroundImage: "url(assets/images/bg/bg_1.png)" }}
      >
        <div className="container">
          <div className="row justify-content-between flex-row-reverse">
            <div className="col-lg-6">
              <div className="image_boxes style_2 relative z-1 h-100">
                <img
                  src="assets/images/about/small_2.jpg"
                  className="small_img wow fadeInUp"
                  alt="img"
                />
                <div className="video_warp style_2 relative z-1 big_img wow fadeInDown">
                  <img src="assets/images/about/about_img_2.jpg" alt="img" />
                  <a
                    href="#"
                    onClick={() => setVideo(true)}
                    className="popup-youtube transform-center justify-content-center d-flex"
                  >
                    <img src="assets/images/icons/youtube.png" alt="icon" />
                  </a>
                </div>
                <img
                  src="assets/images/elements/element_22.png"
                  className="element_1"
                  alt="Element"
                />
                <img
                  src="assets/images/elements/element_23.png"
                  className="element_2 rotate_elem"
                  alt="Element"
                />
                <img
                  src="assets/images/elements/element_24.png"
                  className="element_3 rotate_elem"
                  alt="Element"
                />
              </div>
            </div>
            <div className="col-xl-4 col-lg-6 mb-md-80">
              <div className="section-title left-align wow fadeInUp">
                <p className="subtitle">
                  <i className="fal fa-book" />
                  Who We Are
                </p>
                <h3 className="title">Upgrade Your Skills Build Your Life</h3>
                <p className="mb-0">
                  Quis autem vel eum iure reprehenderit qui in ea voluptate esse
                  quam nihil molestiae conseq uaturvel illum qui dolorem eum
                  fugiat quo voluptas nulla pariatur{" "}
                </p>
              </div>
              <ul className="about_list style_2 mb-xl-30 wow fadeInDown">
                <li>Business Consulting</li>
                <li>Career Development</li>
                <li>Financial Solutions</li>
              </ul>
              <Link to="/about">
                <a className="thm-btn bg-thm-color-two thm-color-two-shadow btn-rectangle wow fadeInUp">
                  Learn More
                  <i className="fal fa-chevron-right ml-2" />
                </a>
              </Link>
            </div>
          </div>
        </div>
      </section> */}
      {/* About Us End */}
      {/* Team Start */}
      {/* <section className="section-padding">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="section-title wow fadeInDown">
                <p className="subtitle">
                  <i className="fal fa-book" />
                  Our Professionals
                </p>
                <h3 className="title">Meet Our Professinoal Team Members</h3>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg col-md-6">
              <div className="team_block style_2 wow fadeInUp">
                <div className="team_img">
                  <img
                    src="assets/images/team/1.jpg"
                    alt="img"
                    data-wow-delay=".10ms"
                    className="image-fit"
                  />
                  <Link to="/team-details">
                    <a className="thm-btn bg-thm-color-two thm-color-two-shadow btn-circle link">
                      <i className="fal fa-plus" />
                    </a>
                  </Link>
                </div>
                <h6 className="mb-1">
                  <Link to="/team-details">
                    <a>Wallace D. Grace</a>
                  </Link>
                </h6>
                <p className="thm-color-two mb-0 font-weight-bold">
                  Coach Teacher
                </p>
              </div>
            </div>
            <div className="col-lg col-md-6">
              <div
                className="team_block style_2 wow fadeInDown"
                data-wow-delay=".20ms"
              >
                <div className="team_img">
                  <img
                    src="assets/images/team/2.jpg"
                    alt="img"
                    className="image-fit"
                  />
                  <Link to="/team-details">
                    <a className="thm-btn bg-thm-color-two thm-color-two-shadow btn-circle link">
                      <i className="fal fa-plus" />
                    </a>
                  </Link>
                </div>
                <h6 className="mb-1">
                  <Link to="/team-details">
                    <a>Evan A. Ethridge</a>
                  </Link>
                </h6>
                <p className="thm-color-two mb-0 font-weight-bold">
                  Coach Teacher
                </p>
              </div>
            </div>
            <div className="col-lg col-md-6">
              <div
                className="team_block style_2 wow fadeInUp"
                data-wow-delay=".30ms"
              >
                <div className="team_img">
                  <img
                    src="assets/images/team/3.jpg"
                    alt="img"
                    className="image-fit"
                  />
                  <Link to="/team-details">
                    <a className="thm-btn bg-thm-color-two thm-color-two-shadow btn-circle link">
                      <i className="fal fa-plus" />
                    </a>
                  </Link>
                </div>
                <h6 className="mb-1">
                  <Link to="/team-details">
                    <a>Marcus L. Duncan</a>
                  </Link>
                </h6>
                <p className="thm-color-two mb-0 font-weight-bold">
                  Coach Teacher
                </p>
              </div>
            </div>
            <div className="col-lg col-md-6">
              <div
                className="team_block style_2 wow fadeInDown"
                data-wow-delay=".40ms"
              >
                <div className="team_img">
                  <img
                    src="assets/images/team/4.jpg"
                    alt="img"
                    className="image-fit"
                  />
                  <Link to="/team-details">
                    <a className="thm-btn bg-thm-color-two thm-color-two-shadow btn-circle link">
                      <i className="fal fa-plus" />
                    </a>
                  </Link>
                </div>
                <h6 className="mb-1">
                  <Link to="/team-details">
                    <a>Paul B. Gilliland</a>
                  </Link>
                </h6>
                <p className="thm-color-two mb-0 font-weight-bold">
                  Coach Teacher
                </p>
              </div>
            </div>
            <div className="col-lg col-md-6">
              <div
                className="team_block style_2 wow fadeInUp"
                data-wow-delay=".50ms"
              >
                <div className="team_img">
                  <img
                    src="assets/images/team/5.jpg"
                    alt="img"
                    className="image-fit"
                  />
                  <Link to="/team-details">
                    <a className="thm-btn bg-thm-color-two thm-color-two-shadow btn-circle link">
                      <i className="fal fa-plus" />
                    </a>
                  </Link>
                </div>
                <h6 className="mb-1">
                  <Link to="/team-details">
                    <a>Donald T.Lewis</a>
                  </Link>
                </h6>
                <p className="thm-color-two mb-0 font-weight-bold">
                  Coach Teacher
                </p>
              </div>
            </div>
            <div className="col-lg col-md-6">
              <div
                className="team_block style_2 wow fadeInDown"
                data-wow-delay=".60ms"
              >
                <div className="team_img">
                  <img
                    src="assets/images/team/6.jpg"
                    alt="img"
                    className="image-fit"
                  />
                  <Link to="/team-details">
                    <a className="thm-btn bg-thm-color-two thm-color-two-shadow btn-circle link">
                      <i className="fal fa-plus" />
                    </a>
                  </Link>
                </div>
                <h6 className="mb-1">
                  <Link to="/team-details">
                    <a>Allan J. Sumlin</a>
                  </Link>
                </h6>
                <p className="thm-color-two mb-0 font-weight-bold">
                  Coach Teacher
                </p>
              </div>
            </div>
            <div className="col-lg col-md-6">
              <div
                className="team_block style_2 wow fadeInUp"
                data-wow-delay=".70ms"
              >
                <div className="team_img">
                  <img
                    src="assets/images/team/7.jpg"
                    alt="img"
                    className="image-fit"
                  />
                  <Link to="/team-details">
                    <a className="thm-btn bg-thm-color-two thm-color-two-shadow btn-circle link">
                      <i className="fal fa-plus" />
                    </a>
                  </Link>
                </div>
                <h6 className="mb-1">
                  <Link to="/team-details">
                    <a>Gracie T. Evans</a>
                  </Link>
                </h6>
                <p className="thm-color-two mb-0 font-weight-bold">
                  Coach Teacher
                </p>
              </div>
            </div>
            <div className="col-lg col-md-6">
              <div
                className="team_block style_2 wow fadeInDown"
                data-wow-delay=".80ms"
              >
                <div className="team_img">
                  <img
                    src="assets/images/team/8.jpg"
                    alt="img"
                    className="image-fit"
                  />
                  <Link to="/team-details">
                    <a className="thm-btn bg-thm-color-two thm-color-two-shadow btn-circle link">
                      <i className="fal fa-plus" />
                    </a>
                  </Link>
                </div>
                <h6 className="mb-1">
                  <Link to="/team-details">
                    <a>David S. Hudson</a>
                  </Link>
                </h6>
                <p className="thm-color-two mb-0 font-weight-bold">
                  Coach Teacher
                </p>
              </div>
            </div>
            <div className="col-lg col-md-6">
              <div
                className="team_block style_2 wow fadeInUp"
                data-wow-delay=".90ms"
              >
                <div className="team_img">
                  <img
                    src="assets/images/team/9.jpg"
                    alt="img"
                    className="image-fit"
                  />
                  <Link to="/team-details">
                    <a className="thm-btn bg-thm-color-two thm-color-two-shadow btn-circle link">
                      <i className="fal fa-plus" />
                    </a>
                  </Link>
                </div>
                <h6 className="mb-1">
                  <Link to="/team-details">
                    <a>Michael J. Randall</a>
                  </Link>
                </h6>
                <p className="thm-color-two mb-0 font-weight-bold">
                  Coach Teacher
                </p>
              </div>
            </div>
            <div className="col-lg col-md-6">
              <div
                className="team_block style_2 wow fadeInDown"
                data-wow-delay=".100ms"
              >
                <div className="team_img">
                  <img
                    src="assets/images/team/10.jpg"
                    alt="img"
                    className="image-fit"
                  />
                  <Link to="/team-details">
                    <a className="thm-btn bg-thm-color-two thm-color-two-shadow btn-circle link">
                      <i className="fal fa-plus" />
                    </a>
                  </Link>
                </div>
                <h6 className="mb-1">
                  <Link to="/team-details">
                    <a>Joseph Anderson</a>
                  </Link>
                </h6>
                <p className="thm-color-two mb-0 font-weight-bold">
                  Coach Teacher
                </p>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      {/* Team End */}
    </Layout>
  );
};

export default About;
