import { Link } from 'react-router-dom';
import { useState } from "react";
import VideoPopup from "../../VideoPopup";

const Home3Banner = () => {
  const [video, setVideo] = useState(false);

  return (
    <div
      className="banner relative z-1"
      style={{ zIndex: video ? "999" : "1" }}
    >
      {video && (
        <VideoPopup close={() => setVideo(false)} videoID="TKnufs85hXk" />
      )}
      <img
        src="assets/images/banner/element_1.png"
        className="element_1"
        alt="Element"
      />
      <img
        src="assets/images/banner/element_line.png"
        className="element_line"
        alt="Element Line"
      />
      {/* Texts */}
      <div className="transform-center">
        <div className="container">
          <div className="row align-items-center intro_banner">
            <div className="col-lg-6">
              <div className="text_box">
                <h1 className="title wow fadeInUp" data-wow-delay=".30ms">
                  <span>Fitness With</span> Kristina
                </h1>
                <p className="wow fadeInUp" data-wow-delay=".40ms">
                  My goal is to help my clients build a positive relationship with physical activity and exercise by focusing on health-related fitness, and the pleasure that movement brings.
                </p>
                <Link to="/contact" className="thm-btn bg-thm-color-two thm-color-two-shadow btn-rounded mr-4 mb-4 wow fadeInRight" data-wow-delay=".50ms">
                  Contact Me <i className="fal fa-chevron-right ml-2" />
                </Link>
                {/* TODO: Intro Video */}
                {/* <a
                  onClick={() => setVideo(true)}
                  className="video_btn video_text popup-youtube mb-4  wow fadeInRight"
                  data-wow-delay=".70ms"
                >
                  <i className="fas fa-play video_icon bg-thm-color-three" />
                  <span>Watch Videos</span>
                </a> */}
              </div>
            </div>
            <div className="col-lg-6 intro_banner_picture">
              <div
                className="image_box relative wow fadeInRight"
                data-wow-delay=".80ms"
              >
                <img
                  src="assets/images/custom/headshot.png"
                  alt="img"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home3Banner;
