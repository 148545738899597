const ServicesGrid = () => <>
    {/* Services Start */}
    {/* TODO: Add pagination */}
    <section className="section-padding section_counter">
        <div className="container">
            <div className="row">
                <div className="col-lg-6" id="service">
                    <div className="service_block shadow_1" wow-data-delay=".10ms">
                        <div className="icon">
                            <img src="assets/images/icons/home.svg" alt="icon" />
                        </div>
                        <div className="text">
                            <h5 className="title">
                                Training Sessions at Home
                            </h5>
                            <p className="mb-0">
                                Individual, Couple or Fun Family training sessions in your home (Equipment Provided)
                            </p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6" id="service">
                    <div className="service_block shadow_1" wow-data-delay=".20ms">
                        <div className="icon">
                            <img src="assets/images/icons/planner.svg" alt="icon" />
                        </div>
                        <div className="text">
                            <h5 className="title">
                                Personalized Training Plans
                            </h5>
                            <p className="mb-0">
                                A personalized workout plan that is designed to assist you on your journey to achieving your fitness goals, by utilizing evidence-based training practices
                            </p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6" id="service">
                    <div className="service_block shadow_1" wow-data-delay=".30ms">
                        <div className="icon">
                            <img src="assets/images/icons/sports_mode.svg" alt="icon" />
                        </div>
                        <div className="text">
                            <h5 className="title">
                                Technique-Focused Sessions
                            </h5>
                            <p className="mb-0">
                                Performing an exercise correctly gives you strength without the strain
                            </p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6" id="service">
                    <div className="service_block shadow_1" wow-data-delay=".40ms">
                        <div className="icon">
                            <img src="assets/images/icons/survey.svg" alt="icon" />
                        </div>
                        <div className="text">
                            <h5 className="title">
                                Initial and Ongoing Assessment
                            </h5>
                            <p className="mb-0">
                                Assessment of body composition, musculoskeletal fitness and aerobic capacity
                            </p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6" id="service">
                    <div className="service_block shadow_1" wow-data-delay=".50ms">
                        <div className="icon">
                            <img src="assets/images/icons/statistics.svg" alt="icon" />
                        </div>
                        <div className="text">
                            <h5 className="title">
                                Supportive, Challenging and Fun Training Style
                            </h5>
                            <p className="mb-0">
                                When your personal trainer asks if you’re “ready”, that’s a rhetorical question!
                            </p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6" id="service">
                    <div className="service_block shadow_1" wow-data-delay=".60ms">
                        <div className="icon">
                            <img src="assets/images/icons/landscape.svg" alt="icon" />
                        </div>
                        <div className="text">
                            <h5 className="title">
                                Lifestyle Councelling
                            </h5>
                            <p className="mb-0">
                                Address physical activity, sleep hygiene, nutrition and sedentary behaviour
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    {/* Services End */}
</>;

export default ServicesGrid;
