import { Link } from 'react-router-dom';
import { Fragment, useState } from "react";
import {
  BlogMenu,
  CoursesMenu,
  HomeMenu,
  PageMenu,
  PortfolioMenu,
} from "./Menu";

const MobileMenu = ({ closeMobileMenu, showMobileMenu }) => {
  const [activeMenu, setActiveMenu] = useState("");
  const activeFun = (name) => setActiveMenu(name === activeMenu ? "" : name);
  const activeLi = (name) =>
    name === activeMenu ? { display: "block" } : { display: "none" };
  return (
    <Fragment>
      <aside
        className={`aside_bar aside_bar_left aside_mobile ${showMobileMenu ? "open" : ""
          }`}
      >
        {/* logo */}
        <Link to="/">
          <span className="logo">
            <img src="assets/images/custom/logo.svg" width="100%" alt="logo" />
          </span>
        </Link>
        {/* logo */}
        {/* Menu */}
        <nav>
          <ul className="main-menu">
            <li className="menu-item">
              <Link to="/">Home</Link>
            </li>
            <li className="menu-item">
              <Link to="/about">About Me</Link>
            </li>
            <li className="menu-item">
              <Link to="/services">Services</Link>
            </li>
            <li className="menu-item">
              <Link to="/pricing">Packages and Pricing</Link>
            </li>
            <li className="menu-item">
              <Link to="/contact">Contact Me</Link>
            </li>
            {/* <li className="menu-item menu-item-has-children">
              <a href="#" onClick={() => activeFun("Pages")}>
                Pages
              </a>
              <ul className="sub-menu" style={activeLi("Pages")}>
                <PageMenu />
              </ul>
            </li> */}
          </ul>
        </nav>
        {/* Menu */}
      </aside>
      <div
        className="aside-overlay trigger-left"
        onClick={() => closeMobileMenu()}
      ></div>
    </Fragment>
  );
};

export default MobileMenu;
