import { useEffect, useState } from 'react';
import axios from 'axios';
import { Link, } from 'react-router-dom';
import FooterContact from "../components/FooterContact";
import PageTitleBanner from "../components/PageTitleBanner";
import Layout from "../layout/Layout";

const Contact = () => {
  const MAPS_API_KEY = "AIzaSyCBjMzkAWPpBGeWI08WUvIMO7X4xJbhPI0";
  const RECAPTCHA_SITE_KEY = "6Lc1eZoeAAAAAP_VN1TN5zk72Ks8kU94PBGwkCHs";
  const CONTACT_API_BASE_URL = process.env.NODE_ENV === "development" ? "http://localhost:3001" : "https://webdna-helper.herokuapp.com";

  const initialFormState = {
    name: "",
    phoneNumber: "",
    email: "",
    message: ""
  };
  const [formData, setFormData] = useState(initialFormState);

  useEffect(() => {
    const chosenPlan = localStorage.getItem('plan');
    const decodedPlan = chosenPlan ? JSON.parse(chosenPlan) : {};
    if (decodedPlan?.audience && decodedPlan?.plan) {
      setFormData({
        ...formData,
        message: `I am interested in the "${decodedPlan.plan}" ${decodedPlan.audience} plan...\n`
      });
      localStorage.removeItem('plan');
    }
  }, []);

  const sendContactEmail = async (e) => {
    // TODO: Add disabled input loading
    // TODO: Change alerts to a different popup

    e.preventDefault();

    if (formData?.name?.length > 0 && formData?.email?.length > 0 && formData?.message?.length > 0) {
      grecaptcha.ready(async function () {
        grecaptcha.execute(RECAPTCHA_SITE_KEY, { action: 'submit' }).then(async function (token) {
          const contactMeResponse = await axios.post(`${CONTACT_API_BASE_URL}/fitnesswithkristina/contact-me`, {
            ...formData,
            token
          }).catch(err => {
            console.log(err);
            alert("Something went wrong, please try again later.");
          });

          const status = contactMeResponse?.data?.status;
          const errorCode = contactMeResponse?.data?.errorCode;
          if (status && !errorCode) {
            setFormData(initialFormState);
            alert("Thank you! Your message was successfully sent!");
          } else if (errorCode === 'sendFail') {
            alert("Something went wrong, please try again later.");
          } else if (errorCode === 'validation') {
            alert("You have some errors on your form, please make sure you've entered all of the required fields.");
          }
        });
      });
    } else {
      alert("You have some errors on your form, please make sure you've entered all of the required fields.");
    }
  }

  return (
    <Layout>
      <PageTitleBanner pageName="Contact Me" />
      <section className="section-padding">
        <div className="container">
          {/* <div className="row">
            <div className="col-12">
              <div className="section-title wow fadeInUp">
                <h3 className="title">Contact Me To Get Started</h3>
              </div>
            </div>
          </div> */}
          {/* <div className="row">
            <div className="col-lg-6">
              <div
                className="contact_faq_box shadow_1 wow fadeInDown"
                data-wow-delay=".30ms"
              >
                <div className="icon">
                  <img
                    src="assets/images/icons/icon_14.png"
                    alt="icon"
                    className="image-fit-contain"
                  />
                </div>
                <div className="text">
                  <h4>Customer Support</h4>
                  <p>
                    Sed ut perspiciatis unde omnis iste natus voluptatem
                    accusantium doloremque laudantium
                  </p>
                  <Link to="/contact" className="thm-btn bg-thm-color-three thm-color-three-shadow btn-rectangle">
                    Take A Coupon <i className="fal fa-chevron-right ml-2" />
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div
                className="contact_faq_box shadow_1 wow fadeInUp"
                data-wow-delay=".40ms"
              >
                <div className="icon">
                  <img
                    src="assets/images/icons/icon_15.png"
                    alt="icon"
                    className="image-fit-contain"
                  />
                </div>
                <div className="text">
                  <h4>Have Any Questions</h4>
                  <p>
                    Sed ut perspiciatis unde omnis iste natus voluptatem
                    accusantium doloremque laudantium
                  </p>
                  <Link to="/contact" className="thm-btn bg-thm-color-two thm-color-two-shadow btn-rectangle">
                    Contact Us <i className="fal fa-chevron-right ml-2" />
                  </Link>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </section>
      {/* Contact Faq Box End */}
      {/* Contact Map & Info Start */}
      <div className="container">
        <div className="row align-items-center">
          <div className="col-xl-5 col-lg-5">
            <div className="section-title left-align wow fadeInDown">
              {/* <p className="subtitle">
                <i className="fal fa-book" />
                Contact Us
              </p> */}
              <h3 className="title">Contact Me To Get Started</h3>
            </div>
            <div className="contact_info mb-md-80">
              <ul>
                <li className="wow fadeInUp">
                  <i className="icon fal fa-map-marker-alt" />
                  <div className="text">
                    <h6>Location</h6>
                    <p>Newmarket, Aurora, Richmond Hill, Bradford</p>
                  </div>
                </li>
                <li className="wow fadeInDown">
                  <i className="icon fal fa-envelope-open-text" />
                  <div className="text">
                    <h6>Email Address</h6>
                    <p>kristinakagan@gmail.com</p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-xl-7 col-lg-7">
            <div className="contact_map relative z-1 wow fadeInRight" id="map">
              <iframe
                src={`https://www.google.com/maps/embed/v1/view?key=${MAPS_API_KEY}&center=44.05947738621079,-79.50735015074042&zoom=12`}
                style={{ border: 0 }}
                allowFullScreen
                loading="lazy"
                width="100%"
                height="100%"
              />
            </div>
          </div>
        </div>
      </div>
      {/* Contact Map & Info End */}
      {/* Contact Form Start */}
      <section className="section">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="section-title wow fadeInDown">
                <p className="subtitle">
                  <i className="fal fa-book" />
                  Drop Me A Message
                </p>
                <h3 className="title">Have Any Questions? Let’s Chat!</h3>
              </div>
            </div>
          </div>
          <form
            className="mf_form_validate ajax_submit wow fadeInUp"
            onSubmit={(e) => sendContactEmail(e)}
            method="post"
            encType="multipart/form-data"
          >
            <div className="row">
              <div className="col-lg-6">
                <div className="form-group form_style">
                  <label>Name*</label>
                  <input
                    type="text"
                    name="name"
                    className="form-control"
                    autoComplete="off"
                    placeholder="Name"
                    value={formData.name || ''}
                    onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                    required
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group form_style">
                  <label>Phone Number</label>
                  <input
                    type="text"
                    name="phone"
                    className="form-control"
                    autoComplete="off"
                    placeholder="Phone Number"
                    value={formData.phoneNumber || ''}
                    onChange={(e) => setFormData({ ...formData, phoneNumber: e.target.value })}
                  />
                </div>
              </div>
              <div className="col-lg-12">
                <div className="form-group form_style">
                  <label>Email Address*</label>
                  <input
                    type="email"
                    name="email"
                    className="form-control"
                    autoComplete="off"
                    placeholder="Email Address"
                    value={formData.email || ''}
                    onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                    required
                  />
                </div>
              </div>
              {/* <div className="col-lg-6">
                <div className="form-group form_style">
                  <label>Subject</label>
                  <input
                    type="text"
                    name="subject"
                    className="form-control"
                    autoComplete="off"
                    placeholder="I Would Like To Discuss"
                  />
                </div>
              </div> */}
              <div className="col-lg-12">
                <div className="form-group form_style">
                  <label>Message*</label>
                  <textarea
                    rows={10}
                    className="form-control"
                    placeholder="Write Message"
                    autoComplete="off"
                    name="message"
                    value={formData.message || ''}
                    onChange={(e) => setFormData({ ...formData, message: e.target.value })}
                    required
                  />
                </div>
              </div>
              <div className="col-lg-12 text-center">
                <button
                  type="submit"
                  className="thm-btn bg-thm-color-three thm-color-three-shadow btn-rectangle"
                >
                  Send Your Message <i className="fal fa-chevron-right ml-2" />
                </button>
              </div>
            </div>
          </form>
        </div>
      </section>
      {/* <FooterContact /> */}
    </Layout>
  );
};

export default Contact;
