import Hom1Cta from "../components/wellcon/Home1/Hom1Cta";
import Hom1skills from "../components/wellcon/Home1/Hom1skills";
import Home1About from "../components/wellcon/Home1/Home1About";
import Home1Banner from "../components/wellcon/Home1/Home1Banner";
import Home1Blog from "../components/wellcon/Home1/Home1Blog";
import Home1Features from "../components/wellcon/Home1/Home1Features";
import Home1Plane from "../components/wellcon/Home1/Home1Plane";
import Home1Portfolio from "../components/wellcon/Home1/Home1Portfolio";
import Home1Pricing from "../components/wellcon/Home1/Home1Pricing";
import Home1Testimonials from "../components/wellcon/Home1/Home1Testimonials";
import Home1VideoBox from "../components/wellcon/Home1/Home1VideoBox";
import Layout from "../layout/Layout";

const Index = () => {
  return (
    <Layout>
      <Home1Banner />
      <Home1About />
      <Home1Features />
      <Hom1skills />
      <Home1Pricing />
      {/* Pricing End */}
      {/* Video Start */}
      <Home1VideoBox />
      {/* Video End */}
      {/* Plane Start */}
      <Home1Plane />
      {/* Plane End */}
      {/* Portfolio Start */}
      <Home1Portfolio />
      {/* Portfolio End */}
      {/* Testimonials Start */}
      <Home1Testimonials />
      {/* Testimonials End */}
      {/* Blog Start */}
      <Home1Blog />
      {/* Blog End */}
      {/* Cta Start */}
      <Hom1Cta />
    </Layout>
  );
};

export default Index;
