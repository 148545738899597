import { Link } from 'react-router-dom';
const Home1Features = () => {
  return (
    <section className="section-padding">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="section-title wow fadeInUp">
              <p className="subtitle">
                <i className="fal fa-book" />
                What I Offer
              </p>
              <h3 className="title">
                Equipment and Exercise Types
              </h3>
            </div>
          </div>
          {/* Box */}
          <div className="col-lg-3 col-md-6">
            <div
              className="features_box style_2 wow fadeInDown"
              data-wow-delay=".20ms"
            >
              <div className="icon">
                <img src="assets/images/custom/exercise/full_body.png" alt="icon" />
              </div>
              <div className="text">
                <h5>Full-Body Dynamic Exercises</h5>
              </div>
            </div>
          </div>
          {/* Box */}
          {/* Box */}
          <div className="col-lg-3 col-md-6">
            <div
              className="features_box style_2 wow fadeInUp"
              data-wow-delay=".30ms"
            >
              <div className="icon">
                {/* TODO: Change picture */}
                <img src="assets/images/custom/exercise/resistance.png" alt="icon" />
              </div>
              <div className="text">
                <h5>Agility Ladder and Cones</h5>
              </div>
            </div>
          </div>
          {/* Box */}
          {/* Box */}
          <div className="col-lg-3 col-md-6">
            <div
              className="features_box style_2 wow fadeInDown"
              data-wow-delay=".40ms"
            >
              <div className="icon">
                <img src="assets/images/custom/exercise/resistance.png" alt="icon" />
              </div>
              <div className="text">
                <h5>Resistance Bands and Elastic Bands</h5>
              </div>
            </div>
          </div>
          {/* Box */}
          {/* Box */}
          <div className="col-lg-3 col-md-6">
            <div
              className="features_box style_2 wow fadeInUp"
              data-wow-delay=".50ms"
            >
              <div className="icon">
                <img src="assets/images/custom/exercise/home.png" alt="icon" />
              </div>
              <div className="text">
                <h5>Utilize Your Home! (Staircase, Backyard, etc.)</h5>
              </div>
            </div>
          </div>
          {/* Box */}
        </div>
      </div>
    </section>
  );
};

export default Home1Features;
