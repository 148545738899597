import { Link } from 'react-router-dom';
const FooterContact = () => {
  return (
    <div className="cta_box style_2">
      <div className="container relative z-2">
        <div className="bg-thm-color-two cta_box_inner z-2 mb-negative">
          <img
            src="assets/images/elements/shape.png"
            alt="shape"
            className="shape_1 zoom-fade"
          />
          <div className="row justify-content-between align-items-center">
            <div className="col-lg-7">
              <div className="text_block wow fadeInLeft">
                <div className="section-title left-align white">
                  {/* <p className="subtitle">
                    <i className="fal fa-book" />
                    Our Professionals
                  </p> */}
                  <h3 className="title">
                    Not ready to commit? Meet with me for a FREE assessment and consultation.
                  </h3>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="image_box relative z-1 wow fadeInRight pricing-questions">
                <img
                  src="assets/images/elements/square_shape.png"
                  alt="shape"
                  className="shape_2 rotate_elem"
                />
                <Link to="/contact" className="thm-btn bg-thm-color-three thm-color-three-shadow btn-rectangle pricing-contact-button">
                  Contact Me Now <i className="fal fa-chevron-right ml-2" />
                </Link>
                {/* <img
                  src="assets/images/cta_image.png"
                  alt="img"
                  className="image-fit"
                /> */}
                <div className="arrows slideRight">
                  <div className="arrow" />
                  <div className="arrow" />
                  <div className="arrow" />
                  <div className="arrow" />
                  <div className="arrow" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="arrows to_up slideTop">
          <div className="arrow" />
          <div className="arrow" />
          <div className="arrow" />
          <div className="arrow" />
          <div className="arrow" />
        </div>
      </div>
    </div>
  );
};

export default FooterContact;
