import { Link } from 'react-router-dom';
import { useState, useRef, useEffect } from "react";
import Slider from "react-slick";
import FooterContact from "../components/FooterContact";
import PageTitleBanner from "../components/PageTitleBanner";
import Price from "../components/Price";
import VideoPopup from "../components/VideoPopup";
import ImagePopup from "../components/ImagePopup";
import Layout from "../layout/Layout";
import ServicesGrid from "../components/custom/ServicesGrid";
// import { testimonial_slider } from "../sliderProps";
import {
  testimonial_slider_main,
  testimonial_slider_main_img,
  testimonial_slider_single_home2,
} from "../sliderProps";
// import { getPagination, pagination } from "../utils";

const Services = () => {
  const [video, setVideo] = useState(false);
  const [graph, setGraph] = useState(false);
  let sort = 2;
  const [active, setActive] = useState(1);
  const [state, setstate] = useState([]);
  const cateredToRef = useRef(null)
  // useEffect(() => {
  //   pagination("#service", sort, active);
  //   let list = document.querySelectorAll("#service");
  //   setstate(getPagination(list.length, sort));
  // }, [active]);

  useEffect(() => {
    const hash = window.location.hash;
    if (hash && hash === "#catered-to-your-needs" && cateredToRef?.current) {
      cateredToRef.current.scrollIntoView();
    }
  }, []);

  return (
    <Layout footerStyle={1}>
      <PageTitleBanner pageName="Services" />

      {video && <VideoPopup close={() => setVideo(false)} />}
      {graph && <ImagePopup close={() => setGraph(false)} imageUrl="assets/images/custom/graph.png" />}
      <ServicesGrid />
      {/* Testimonials Start */}
      <section
        className="section section-bg relative z-1 bg-thm-color-one anchor-wrapper"
        style={{ backgroundImage: "url(assets/images/bg/bg_4.png)" }}
      >
        <div className="anchor offset-160" ref={cateredToRef}></div>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="section-title left-align white wow fadeInDown">
                <h3 className="title mb-0">
                  Catered To Your Needs
                </h3>
              </div>
            </div>
          </div>
          <div className="row">
            {/* <Slider
            {...testimonial_slider}
            className="testimonial_slider style_2 row wow fadeInUp"
          > */}
            {/* Testimonial Item */}
            <div className="slide-item testimonials col-4">
              <div className="testimonial_item style_dark">
                <div className="author">
                  {/* <div className="image bg-thm-color-two">
                    <img
                      src="assets/images/author/1.jpg"
                      alt="img"
                      className="image-fit"
                    />
                  </div> */}
                  <div className="text">
                    <h6 className="mb-0">Individual Personal Training</h6>
                  </div>
                </div>
                <p className="comment">
                  One-on-one sessions focus on helping you achieve your personal goals by providing you with the tools and knowledge necessary. I will do an initial assessment of body composition, musculoskeletal strength, aerobic capacity, and will repeat this assessment every 6 weeks to monitor ongoing success.
                </p>
              </div>
            </div>
            {/* Testimonial Item */}
            {/* Testimonial Item */}
            <div className="slide-item testimonials col-4">
              <div className="testimonial_item style_dark">
                <div className="author">
                  {/* <div className="image bg-thm-color-two">
                    <img
                      src="assets/images/author/2.jpg"
                      alt="img"
                      className="image-fit"
                    />
                  </div> */}
                  <div className="text">
                    <h6 className="mb-0">Couples Sessions</h6>
                  </div>
                </div>
                <p className="comment">
                  Research suggests that exercising with your romantic partner may <a href="https://journals.sagepub.com/doi/abs/10.1177/02654075211012086" target="_blank" alt="Read Study" title="Read Study">increase relationship satisfaction</a> and is correlated with a <a href="https://www.psychologytoday.com/ca/blog/finding-new-home/202111/why-couples-should-work-out-together" target="_blank" alt="Read Study" title="Read Study">higher positive mood</a>. By partnering up with your partner to reach your health and fitness goals, you create an opportunity to bond over a shared hobby. This is an amazing way to continuously improve and strengthen your relationship while also working on your own health and happiness.
                </p>
              </div>
            </div>
            {/* Testimonial Item */}
            {/* Testimonial Item */}
            <div className="slide-item testimonials col-4">
              <div className="testimonial_item style_dark">
                <div className="author">
                  {/* <div className="image bg-thm-color-two">
                    <img
                      src="assets/images/author/3.jpg"
                      alt="img"
                      className="image-fit"
                    />
                  </div> */}
                  <div className="text">
                    <h6 className="mb-0">Group Sessions for the Entire Family</h6>
                  </div>
                </div>
                <p className="comment">
                  The main focus of these dynamic family sessions is to create a fun and enthusiastic relationship with exercise and physical activity for the entire family. This positive relationship builds the foundation to having an active and healthy lifestyle for you and your children forever.
                </p>
              </div>
            </div>
            {/* Testimonial Item */}
            {/* </Slider> */}
          </div>
        </div>
      </section>
      {/* Testimonials End */}
      {/* Video Quote Start */}
      {/* <section className="bg-thm-color-two-gradient z-1 video_quote">
        <img
          src="assets/images/elements/element_2.png"
          className="element_2"
          alt="Element"
        />
        <div className="container-fluid p-0">
          <div className="row no-gutters align-items-center">
            <div className="col-lg-6">
              <div className="video_warp relative z-1 h-100">
                <img
                  src="assets/images/video_img_3.png"
                  alt="img"
                  className="image-fit"
                />
                <a
                  onClick={() => setVideo(true)}
                  href="#"
                  className="popup-youtube video_btn transform-center justify-content-center d-flex style_2"
                >
                  <i className="fas fa-play video_icon bg-thm-color-three pulse-animated" />
                </a>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="arrows slideRight">
                <div className="arrow" />
                <div className="arrow" />
                <div className="arrow" />
                <div className="arrow" />
                <div className="arrow" />
              </div>
              <img
                src="assets/images/elements/element_11.png"
                className="element_4 rotate_elem"
                alt="img"
              />
              <div className="quote_sec about relative z-1">
                <img
                  src="assets/images/elements/element_17.png"
                  className="element_5 rotate_elem"
                  alt="img"
                />
                <div className="section-title left-align wow fadeInDown">
                  <p className="subtitle">
                    <i className="fal fa-book" />
                    Admission Going On
                  </p>
                  <h3 className="title mb-0">
                    Find Your Best Coach With Wellco
                  </h3>
                </div>
                <p>
                  Sed ut perspiciatis unde omnis iste natus error sit voluptatem
                  accusantium doloremque laudtium totam rem aperiam eaque quae
                </p>
                <form
                  className="wow fadeInUp"
                  onSubmit={(e) => e.preventDefault()}
                >
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="form-group">
                        <input
                          type="text"
                          name="#"
                          className="form-control form-control-custom"
                          placeholder="Your Name"
                          autoComplete="off"
                        />
                        <i className="fal fa-user" />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <input
                          type="text"
                          name="#"
                          className="form-control form-control-custom"
                          placeholder="Email Address"
                          autoComplete="off"
                        />
                        <i className="fal fa-envelope" />
                      </div>
                    </div>
                    <div className="col-sm-12">
                      <button
                        type="submit"
                        className="thm-btn bg-thm-color-two thm-color-two-shadow btn-rectangle"
                      >
                        Enroll Now <i className="fal fa-chevron-right ml-2" />
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      {/* Video Quote End */}
      {/* <FooterContact /> */}
      {/* Testimonials Start */}
      <section
        className="section section-bg"
        style={{ backgroundImage: "url(assets/images/bg/bg_3.png)" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="section-title wow fadeInDown">
                <h3 className="title">Initial and Ongoing Assessments</h3>
                <p className="subtitle">
                  <i className="fal fa-book" />
                  FREE Assessment Every 6 Weeks
                </p>
              </div>
            </div>
          </div>
          <Slider
            {...testimonial_slider_main}
            className="testimonial_slider_main mb-xl-30 justify-content-center wow fadeInUp"
          >
            <div className="slide-item assessment row">
              <div className="testimonial_item style_3 col-lg-8">
                <div className="comment">
                  <div className="slide_column">
                    <b>Skinfold measurements</b> - to monitor your ongoing body composition transformation.
                  </div>
                  <div className="slide_column">
                    <img src="assets/images/custom/assessments/skin_fold.jpeg" />
                  </div>
                </div>
              </div>
            </div>
            <div className="slide-item assessment row">
              <div className="testimonial_item style_3 col-lg-8">
                <div className="comment">
                  <div className="slide_column">
                    <b>Waist Circumference</b> - an important and <a href="https://pubmed.ncbi.nlm.nih.gov/32020062/" target="_blank" title="Click for more information">vital sign</a> of overall health
                  </div>
                  <div className="slide_column">
                    <img src="assets/images/custom/assessments/waist_circumference.jpeg" />
                  </div>
                </div>
              </div>
            </div>
            <div className="slide-item assessment row">
              <div className="testimonial_item style_3 col-lg-8">
                <div className="comment">
                  <div className="slide_column">
                    <b>Musculoskeletal Fitness (MSK)</b> - measurements that are used for determining the Composite Scores for Health MSK Fitness
                  </div>
                  <div className="slide_column">
                    <img src="assets/images/custom/assessments/grip_strength.png" />
                  </div>
                </div>
              </div>
            </div>
            <div className="slide-item assessment row">
              <div className="testimonial_item style_3 col-lg-8">
                <div className="comment">
                  <div className="slide_column">
                    <b>Cardiorespiratory Fitness</b> - one of the most important components of physical fitness and is correlated with numerous health markers in <a href="https://pubmed.ncbi.nlm.nih.gov/25269064/" target="_blank" title="Click for more information">adults</a> and <a href="https://jamanetwork.com/journals/jamapediatrics/fullarticle/2770130" target="_blank" title="Click for more information">children</a>
                  </div>
                  <div className="slide_column">
                    <img src="assets/images/custom/assessments/cardio.jpeg" />
                  </div>
                </div>
              </div>
            </div>
          </Slider>
        </div>
      </section>
      {/* Testimonials End */}
    </Layout>
  );
};

export default Services;
