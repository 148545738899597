import { Link, useNavigate } from 'react-router-dom';
import { Nav, Tab } from "react-bootstrap";

/**
 * TODO: wow.sync();
 * On tab click elements are not found by WOW
 */
const Price = () => {
  const navigate = useNavigate();

  const trackPlan = (e, audience, plan) => {
    e.preventDefault();
    localStorage.setItem("plan", JSON.stringify({ audience, plan }));

    navigate("/contact");
  }

  return (
    <Tab.Container defaultActiveKey="individual">
      <Nav
        as="ul"
        className="nav nav-tabs style_1 border-0 justify-content-center justify-content-lg-end wow fadeInRight"
      >
        <Nav.Item as="li">
          <Nav.Link
            eventKey="individual"
            href="#individual"
            className="nav-link mb-0 border-0 thm-btn bg-thm-color-one  btn-rectangle "
            data-toggle="tab"
          >
            Individual <i className="fal fa-chevron-right ml-2" />
          </Nav.Link>
        </Nav.Item>
        <Nav.Item as="li">
          <Nav.Link
            eventKey="couple"
            href="#couple"
            className="nav-link mb-0 border-0 thm-btn bg-thm-color-one btn-rectangle"
            data-toggle="tab"
          >
            Couple <i className="fal fa-chevron-right ml-2" />
          </Nav.Link>
        </Nav.Item>
        <Nav.Item as="li">
          <Nav.Link
            eventKey="family"
            href="#family"
            className="nav-link mb-0 border-0 thm-btn bg-thm-color-one btn-rectangle"
            data-toggle="tab"
          >
            Family <i className="fal fa-chevron-right ml-2" />
          </Nav.Link>
        </Nav.Item>
      </Nav>
      <Tab.Content>
        <Tab.Pane eventKey="individual">
          <div className="row no-gutters justify-content-center">
            <div className="col-lg-4 col-md-6">
              <div className="plan_box wow fadeInDown">
                <h4 className="plan_title">Healthy</h4>
                <p className="plan_subtext">
                  2 Sessions / Week for 6 weeks
                </p>
                <h2 className="plan_price">$70<small> / Session</small></h2>
                <ul className="about_list style_2 mb-xl-30">
                  <li>Assessment Included</li>
                  <li>Equipment Included</li>
                  <li>Personalized Training Plans</li>
                </ul>
                <Link to="/contact" onClick={(e) => { trackPlan(e, 'Individual', 'Healthy') }} className="thm-btn bg-thm-color-two-light thm-color-one btn-rectangle">
                  Select Plan <i className="fal fa-chevron-right ml-2" />
                </Link>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="plan_box active wow fadeInUp">
                <h4 className="plan_title">Active</h4>
                <p className="plan_subtext">
                  3 Sessions / Week for 6 weeks
                </p>
                <h2 className="plan_price">$65<small> / Session</small></h2>
                <ul className="about_list style_2 mb-xl-30">
                  <li>Assessment Included</li>
                  <li>Equipment Included</li>
                  <li>Personalized Training Plans</li>
                </ul>
                <Link to="/contact" onClick={(e) => { trackPlan(e, 'Individual', 'Active') }} className="thm-btn bg-thm-color-two-light thm-color-one btn-rectangle">
                  Select Plan <i className="fal fa-chevron-right ml-2" />
                </Link>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="plan_box wow fadeInDown">
                <h4 className="plan_title">Beast</h4>
                <p className="plan_subtext">
                  4 Sessions / Week for 6 weeks
                </p>
                <h2 className="plan_price">$60<small> / Session</small></h2>
                <ul className="about_list style_2 mb-xl-30">
                  <li>Assessment Included</li>
                  <li>Equipment Included</li>
                  <li>Personalized Training Plans</li>
                </ul>
                <Link to="/contact" onClick={(e) => { trackPlan(e, 'Individual', 'Beast') }} className="thm-btn bg-thm-color-two-light thm-color-one btn-rectangle">
                  Select Plan <i className="fal fa-chevron-right ml-2" />
                </Link>
              </div>
            </div>
          </div>
        </Tab.Pane>
        <Tab.Pane eventKey="couple">
          <div className="row no-gutters justify-content-center">
            <div className="col-lg-4 col-md-6">
              <div className="plan_box wow fadeInDown">
                <h4 className="plan_title">Healthy</h4>
                <p className="plan_subtext">
                  2 Sessions / Week for 6 weeks
                </p>
                <h2 className="plan_price">$90<small> / Session</small></h2>
                <ul className="about_list style_2 mb-xl-30">
                  <li>Assessment Included</li>
                  <li>Equipment Included</li>
                  <li>Personalized Training Plans</li>
                </ul>
                <Link to="/contact" onClick={(e) => { trackPlan(e, 'Couple', 'Healthy') }} className="thm-btn bg-thm-color-two-light thm-color-one btn-rectangle">
                  Select Plan <i className="fal fa-chevron-right ml-2" />
                </Link>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="plan_box active wow fadeInUp">
                <h4 className="plan_title">Active</h4>
                <p className="plan_subtext">
                  3 Sessions / Week for 6 weeks
                </p>
                <h2 className="plan_price">$85<small> / Session</small></h2>
                <ul className="about_list style_2 mb-xl-30">
                  <li>Assessment Included</li>
                  <li>Equipment Included</li>
                  <li>Personalized Training Plans</li>
                </ul>
                <Link to="/contact" onClick={(e) => { trackPlan(e, 'Couple', 'Active') }} className="thm-btn bg-thm-color-two-light thm-color-one btn-rectangle">
                  Select Plan <i className="fal fa-chevron-right ml-2" />
                </Link>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="plan_box wow fadeInDown">
                <h4 className="plan_title">Beast</h4>
                <p className="plan_subtext">
                  4 Sessions / Week for 6 weeks
                </p>
                <h2 className="plan_price">$80<small> / Session</small></h2>
                <ul className="about_list style_2 mb-xl-30">
                  <li>Assessment Included</li>
                  <li>Equipment Included</li>
                  <li>Personalized Training Plans</li>
                </ul>
                <Link to="/contact" onClick={(e) => { trackPlan(e, 'Couple', 'Beast') }} className="thm-btn bg-thm-color-two-light thm-color-one btn-rectangle">
                  Select Plan <i className="fal fa-chevron-right ml-2" />
                </Link>
              </div>
            </div>
          </div>
        </Tab.Pane>
        <Tab.Pane eventKey="family">
          <div className="row no-gutters justify-content-center">
            <div className="col-lg-4 col-md-6">
              <div className="plan_box wow fadeInDown">
                <h4 className="plan_title">Healthy</h4>
                <p className="plan_subtext">
                  2 Sessions / Week for 6 weeks
                </p>
                <h2 className="plan_price">$100<small> / Session</small></h2>
                <ul className="about_list style_2 mb-xl-30">
                  <li>Assessment Included</li>
                  <li>Equipment Included</li>
                  <li>Personalized Training Plans</li>
                </ul>
                <Link to="/contact" onClick={(e) => { trackPlan(e, 'Family', 'Healthy') }} className="thm-btn bg-thm-color-two-light thm-color-one btn-rectangle">
                  Select Plan <i className="fal fa-chevron-right ml-2" />
                </Link>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="plan_box active wow fadeInUp">
                <h4 className="plan_title">Active</h4>
                <p className="plan_subtext">
                  3 Sessions / Week for 6 weeks
                </p>
                <h2 className="plan_price">$95<small> / Session</small></h2>
                <ul className="about_list style_2 mb-xl-30">
                  <li>Assessment Included</li>
                  <li>Equipment Included</li>
                  <li>Personalized Training Plans</li>
                </ul>
                <Link to="/contact" onClick={(e) => { trackPlan(e, 'Family', 'Active') }} className="thm-btn bg-thm-color-two-light thm-color-one btn-rectangle">
                  Select Plan <i className="fal fa-chevron-right ml-2" />
                </Link>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="plan_box wow fadeInDown">
                <h4 className="plan_title">Beast</h4>
                <p className="plan_subtext">
                  4 Sessions / Week for 6 weeks
                </p>
                <h2 className="plan_price">$90<small> / Session</small></h2>
                <ul className="about_list style_2 mb-xl-30">
                  <li>Assessment Included</li>
                  <li>Equipment Included</li>
                  <li>Personalized Training Plans</li>
                </ul>
                <Link to="/contact" onClick={(e) => { trackPlan(e, 'Family', 'Beast') }} className="thm-btn bg-thm-color-two-light thm-color-one btn-rectangle">
                  Select Plan <i className="fal fa-chevron-right ml-2" />
                </Link>
              </div>
            </div>
          </div>
        </Tab.Pane>
      </Tab.Content>
    </Tab.Container >
  );
};

export default Price;
