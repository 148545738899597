import { render } from "react-dom";
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import Index from "./pages/index.js"
import Index2 from "./pages/index-2.js"
import Index3 from "./pages/index-3.js"
import About2 from "./pages/about-2.js";
import About from "./pages/about.js";
import BlogDetails from "./pages/blog-details.js";
import BlogGrid from "./pages/blog-grid.js";
import BlogList from "./pages/blog-list.js";
import BlogMasonry from "./pages/blog-masonry.js";
import Contact from "./pages/contact.js";
import CourseDetails from "./pages/course-details.js";
import Courses from "./pages/courses.js";
import EventDetails from "./pages/event-details.js";
import Events from "./pages/events.js";
import Faqs from "./pages/faqs.js";
import PortfolioDetails from "./pages/portfolio-details.js";
import PortfolioGallery from "./pages/portfolio-gallery.js";
import PortfolioGrid from "./pages/portfolio-grid.js";
import PortfolioMasonry from "./pages/portfolio-masonry.js";
import Pricing from "./pages/pricing.js";
import Services from "./pages/services.js";
import TeamDetails from "./pages/team-details.js";
import Team from "./pages/team.js";
import Testimonials from "./pages/testimonials.js";
import Videos from "./pages/videos.js";

const rootElement = document.getElementById("root");
render(
  <div className="wrapper">
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Index />} />
        <Route path="/index-2" element={<Index2 />} />
        <Route path="/index-3" element={<Index3 />} />
        <Route path="/about-2" element={<About2 />} />
        <Route path="/about" element={<About />} />
        <Route path="/blog-details" element={<BlogDetails />} />
        <Route path="/blog-grid" element={<BlogGrid />} />
        <Route path="/blog-list" element={<BlogList />} />
        <Route path="/blog-masonry" element={<BlogMasonry />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/course-details" element={<CourseDetails />} />
        <Route path="/courses" element={<Courses />} />
        <Route path="/event-details" element={<EventDetails />} />
        <Route path="/events" element={<Events />} />
        <Route path="/faqs" element={<Faqs />} />
        <Route path="/portfolio-details" element={<PortfolioDetails />} />
        <Route path="/portfolio-gallery" element={<PortfolioGallery />} />
        <Route path="/portfolio-grid" element={<PortfolioGrid />} />
        <Route path="/portfolio-masonry" element={<PortfolioMasonry />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/services" element={<Services />} />
        <Route path="/team-details" element={<TeamDetails />} />
        <Route path="/team" element={<Team />} />
        <Route path="/testimonials" element={<Testimonials />} />
        <Route path="/videos" element={<Videos />} />
      </Routes>
    </BrowserRouter>
  </div>,
  rootElement
);
