import Home3Plane from "../components/wellcon/home3/Home3Plane";
import Home1About from "../components/wellcon/Home1/Home1About";
import Home3About from "../components/wellcon/home3/Home3About";
import Home3Banner from "../components/wellcon/home3/Home3Banner";
import Home3Coach from "../components/wellcon/home3/Home3Coach";
import Home3Cta from "../components/wellcon/home3/Home3Cta";
import Home1Features from "../components/wellcon/Home1/Home1Features";
import Home3Newsletter from "../components/wellcon/home3/Home3Newsletter";
import Home3Sponsors from "../components/wellcon/home3/Home3Sponsors";
import Home3Team from "../components/wellcon/home3/Home3Team";
import Home3Testimonials from "../components/wellcon/home3/Home3Testimonials";
import Home3Video from "../components/wellcon/home3/Home3Video";
import Home3Why from "../components/wellcon/home3/Home3Why";
import Layout from "../layout/Layout";
import ServicesGrid from "../components/custom/ServicesGrid";
import { useEffect } from "react";

const Index2 = () => {
  return (
    <Layout noHeaderTop absolute headerStyle={1} footerStyle={3}>
      <Home3Banner />
      {/* Banner End */}
      {/* Features Start */}
      <Home1About />
      <ServicesGrid />
      <Home1Features />
      {/* Features End */}
      {/* About Start */}
      {/* <Home3About /> */}
      {/* About End */}
      {/* Team Start */}
      {/* <Home3Team /> */}
      {/* Team End */}
      {/* Cta Start */}
      {/* <Home3Cta /> */}
      {/* Cta End */}
      {/* Video Start */}
      {/* <Home3Video /> */}
      {/* Video End */}
      {/* Testimonials Start */}
      {/* <Home3Testimonials /> */}
      {/* Testimonials End */}
      {/* Coach Grid Start */}
      {/* <Home3Coach /> */}
      {/* Coach Grid End */}
      {/* Sponsors Start */}
      {/* <Home3Sponsors /> */}
      {/* Sponsors End */}
      {/* Newsletter Start */}
      {/* <Home3Newsletter /> */}
      {/* Newsletter End */}
      {/* Why Us Start */}
      {/* <Home3Why /> */}
      {/* Why Us End */}
      {/* Plane Start */}
      <Home3Plane />
    </Layout>
  );
};

export default Index2;
